import * as auth from './auth.js';
import {show_loading_screen} from './loading.js';


(show_loading_screen(async () => {

  // get authentication token if a URL code=... token is present from an oauth redirect

  await auth.extract_url_token();


  // Prepare url with the original hash in case auth.test_login() redirects us to #/login

  const oauth_url = auth.get_oauth_url();


  // test stored user credentials

  const result = await auth.test_login();



  if(!window.isElectron && !result) {

    // Redirect user to login page/get oauth token automatically in browser.

    window.location.href = oauth_url;


    // hang forever so the user doesn't see the log in button while it's performing redirects
    await new Promise(() => {});

  }

}))();
